// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  defaultauth: 'firebase',
  cf_base: 'https://us-central1-fitlego-e1026.cloudfunctions.net',
  challenge_cf_base: 'https://tracker.fitlego.com/login/',
  firebaseConfig: {
    apiKey: 'AIzaSyBJWD3_7vSAjAZ6k0-zK50Sh7I2Yv7NKU8',
    authDomain: 'fitlego-e1026.firebaseapp.com',
    databaseURL: 'https://fitlego-e1026-default-rtdb.firebaseio.com',
    projectId: 'fitlego-e1026',
    storageBucket: 'fitlego-e1026.appspot.com',
    messagingSenderId: '845229506812',
    appId: '1:845229506812:web:a2a6962517ce9066ea1779',
    measurementId: 'G-BVVMFWDTBN'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
